import _extends from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import React from 'react';
import { colours } from "../../../../../../constants/css";
import { calculateDaysLeft } from "../../../../../Offer/offerUtils";
import { pointsCalculator, merchantCardCallout } from "../../../../../../constants/app";
import CardFlag from "../../BaseMerchantCard/CardFlag";
var OlmTopSideCard = function OlmTopSideCard(_ref) {
  var merchant = _ref.merchant,
    bonusRibbons = _ref.bonusRibbons;
  var cardFlagLabel = merchant.is_elevated ?
  // eslint-disable-next-line max-len
  pointsCalculator(parseInt(merchant.rebate.rebate_user, 10), parseInt(merchant.was_rebate.rebate_user, 10)) : merchantCardCallout(merchant);
  if (!merchant.is_elevated || !merchant.rebate.end_date && !merchant.was_rebate.rebate_user) {
    return null;
  }
  var daysLeft = calculateDaysLeft(merchant.rebate.end_date);
  var bonusRibbon = bonusRibbons.find(function (b) {
    return b.name === cardFlagLabel;
  });
  if (bonusRibbon) {
    return __jsx(CardFlag, {
      label: cardFlagLabel,
      days: daysLeft,
      bgColor: bonusRibbon.backgroundColor,
      color: bonusRibbon.color
    });
  }
  var flagBgColor;
  if (['TRIPLE POINTS', 'DOUBLE POINTS'].includes(cardFlagLabel)) {
    flagBgColor = colours.calloutPink;
  } else if (['NEW STORE', 'FREE SHIPPING', 'PROMO CODE'].includes(cardFlagLabel)) {
    flagBgColor = colours.calloutGrey;
  }
  return __jsx(CardFlag, _extends({
    label: cardFlagLabel,
    bgColor: flagBgColor
  }, daysLeft && {
    days: daysLeft
  }));
};
OlmTopSideCard.defaultProps = {
  bonusRibbons: []
};
export default OlmTopSideCard;