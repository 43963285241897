import styled from 'styled-components';
import { Container, Button } from "../../_global";
import { colours, fontSizes, headingSizes, customFonts, responsiveQuery } from "../../../constants/css";
export var H2Styled = styled.h2.withConfig({
  displayName: "style__H2Styled",
  componentId: "sc-o3vsh2-0"
})(["font-size:", ";font-weight:normal;font-family:", ";margin-top:1rem;margin-bottom:0;width:100%;@media only screen and ", "{font-size:", ";max-width:508px;margin-top:1rem;}"], headingSizes.h1, customFonts.ciutadellaReg, responsiveQuery.desktop, headingSizes.h1);
export var H0Styled = styled.h2.withConfig({
  displayName: "style__H0Styled",
  componentId: "sc-o3vsh2-1"
})(["font-size:", ";font-weight:normal;font-family:", ";margin-top:1rem;margin-bottom:", ";width:100%;@media only screen and ", "{font-size:", ";max-width:508px;}"], headingSizes.h1, customFonts.ciutadellaReg, function (props) {
  return props.hasSubheading ? 0 : '24px';
}, responsiveQuery.desktop, headingSizes.h0);
export var PStyled = styled.p.withConfig({
  displayName: "style__PStyled",
  componentId: "sc-o3vsh2-2"
})(["margin:16px 0 24px 0;font-size:", ";line-height:1.56;width:100%;"], fontSizes.large);
export var ButtonStyled = styled(Button).withConfig({
  displayName: "style__ButtonStyled",
  componentId: "sc-o3vsh2-3"
})(["width:100%;margin-bottom:24px;"]);
export var ProgressHero = styled.div.withConfig({
  displayName: "style__ProgressHero",
  componentId: "sc-o3vsh2-4"
})(["text-align:center;display:flex;align-items:center;h1{font-size:1.625rem;font-weight:normal;font-family:", ";margin-bottom:0;}"], customFonts.ciutadellaMed);
export var GostWrapper = styled.div.withConfig({
  displayName: "style__GostWrapper",
  componentId: "sc-o3vsh2-5"
})(["position:absolute;left:-999999px;display:none;opacity:0;visibility:hidden;"]);
export var ProgressWrapper = styled.div.withConfig({
  displayName: "style__ProgressWrapper",
  componentId: "sc-o3vsh2-6"
})(["display:flex;justify-content:flex-start;align-items:center;position:relative;padding:40px 0;margin-top:12px;min-height:260px;background:", ";> div{width:100%;> div{margin-bottom:0;}}@media only screen and ", "{min-height:330px;}"], colours.qantasIceWhite, responsiveQuery.desktop);
export var LogoWrapper = styled.div.withConfig({
  displayName: "style__LogoWrapper",
  componentId: "sc-o3vsh2-7"
})(["> img{height:2rem;@media only screen and ", "{height:3rem;}&:nth-child(2){margin:0 2rem;}}"], responsiveQuery.desktop);
export var ProgressContainer = styled(Container).withConfig({
  displayName: "style__ProgressContainer",
  componentId: "sc-o3vsh2-8"
})(["@media only screen and ", "{padding:0;max-width:100%;}"], responsiveQuery.desktop);
export var IconContainer = styled.div.withConfig({
  displayName: "style__IconContainer",
  componentId: "sc-o3vsh2-9"
})(["display:flex;justify-content:center;align-items:center;margin-bottom:16px;margin-top:19px;"]);
export var Icon = styled.img.withConfig({
  displayName: "style__Icon",
  componentId: "sc-o3vsh2-10"
})(["width:88px;height:33px;margin:0 9px;@media only screen and ", "{width:100px;height:37px;}"], responsiveQuery.desktop);
export var HowIcon = styled.img.withConfig({
  displayName: "style__HowIcon",
  componentId: "sc-o3vsh2-11"
})(["width:48px;height:48px;"]);
export var ActivatingText = styled.p.withConfig({
  displayName: "style__ActivatingText",
  componentId: "sc-o3vsh2-12"
})(["text-align:center;margin-top:32px;@media only screen and ", "{margin-top:40px;}"], responsiveQuery.desktop);
export var HowBlock = styled.div.withConfig({
  displayName: "style__HowBlock",
  componentId: "sc-o3vsh2-13"
})(["display:flex;flex-direction:column;justify-content:center;text-align:center;align-items:center;width:100%;@media only screen and ", "{width:auto;}p{font-family:", ";font-size:", ";margin-bottom:0;}"], responsiveQuery.desktop, customFonts.ciutadellaMed, fontSizes.large);
export var HowWrapper = styled.section.withConfig({
  displayName: "style__HowWrapper",
  componentId: "sc-o3vsh2-14"
})(["background:", ";padding:3rem 0;> div{display:flex;flex-wrap:wrap;justify-content:space-between;max-width:240px;padding:0;@media only screen and ", "{max-width:", ";padding:0 inherit;}> p{font-family:", ";font-size:", ";text-align:center;width:100%;margin:0 auto 3rem;}}", "{width:", ";}"], function (props) {
  return props.vertical === true ? colours.qantasWhite : colours.qantasIceWhite;
}, responsiveQuery.desktop, function (props) {
  return props.vertical !== true && '700px';
}, customFonts.ciutadellaMed, fontSizes.large, HowBlock, function (props) {
  return props.vertical === true && '100%';
});
export var LogoFooter = styled.section.withConfig({
  displayName: "style__LogoFooter",
  componentId: "sc-o3vsh2-15"
})(["padding:2rem 0;text-align:center;max-width:450px;margin:auto;p{line-height:1.8;font-size:", ";}img{max-width:100%;}"], fontSizes.large);
export var LinkCTAWrapper = styled(Container).withConfig({
  displayName: "style__LinkCTAWrapper",
  componentId: "sc-o3vsh2-16"
})(["text-align:center;display:flex;flex-direction:column;justify-content:center;align-items:center;padding:0;@media only screen and ", "{max-width:100%;}a{text-decoration:none;width:100%;}"], responsiveQuery.desktop);
export var MainCopyContainer = styled.div.withConfig({
  displayName: "style__MainCopyContainer",
  componentId: "sc-o3vsh2-17"
})(["display:flex;flex-direction:column;justify-content:center;align-items:center;color:", ";@media only screen and ", "{max-width:620px;}"], colours.qantasCharcoal, responsiveQuery.desktop);
export var SuccesssWrapper = styled(LinkCTAWrapper).withConfig({
  displayName: "style__SuccesssWrapper",
  componentId: "sc-o3vsh2-18"
})([""]);
export var BannerContainer = styled.div.withConfig({
  displayName: "style__BannerContainer",
  componentId: "sc-o3vsh2-19"
})(["background:", ";width:100%;padding:40px 0;"], colours.qantasWhite);
export var FaqBlock = styled.div.withConfig({
  displayName: "style__FaqBlock",
  componentId: "sc-o3vsh2-20"
})(["text-align:center;margin-top:3rem;p{font-size:", ";color:", ";}a{font-size:", ";}"], fontSizes.small, colours.qantasGrey40, fontSizes.small);
export var ErrorMessageContainer = styled.div.withConfig({
  displayName: "style__ErrorMessageContainer",
  componentId: "sc-o3vsh2-21"
})(["background:", ";padding:24px 24px 32px 24px;margin-bottom:24px;@media only screen and ", "{min-width:400px;}"], colours.qantasWarningHover, responsiveQuery.desktop);
export var ErrorMessage = styled.div.withConfig({
  displayName: "style__ErrorMessage",
  componentId: "sc-o3vsh2-22"
})(["display:flex;flex-direction:column;justify-content:center;align-items:center;margin-top:16px;max-width:400px;"]);
export var ErrorTryAgainButton = styled(Button).withConfig({
  displayName: "style__ErrorTryAgainButton",
  componentId: "sc-o3vsh2-23"
})(["border:none;background:none;text-decoration:underline;font-size:1.375rem;font-weight:normal;font-family:", ";font-style:normal;font-stretch:normal;line-height:1.33;letter-spacing:normal;padding:0;text-transform:lowercase;outline:none;@media only screen and ", "{font-size:", ";cursor:pointer;}"], customFonts.ciutadellaMed, responsiveQuery.desktop, fontSizes.xxl);
export var ErrorButtonContainer = styled.div.withConfig({
  displayName: "style__ErrorButtonContainer",
  componentId: "sc-o3vsh2-24"
})(["", "{width:100%;}@media only screen and ", "{width:446px;}"], ButtonStyled, responsiveQuery.desktop);
export var SuccessMessageBoxTop = styled.div.withConfig({
  displayName: "style__SuccessMessageBoxTop",
  componentId: "sc-o3vsh2-25"
})(["display:flex;max-width:386px;min-height:110px;position:relative;border-radius:4px;box-shadow:0 1px 1px 0 rgba(0,0,0,0.1);background-color:", ";margin-bottom:24px;@media only screen and ", "{max-width:394px;}"], colours.qantasWhite, responsiveQuery.desktop);
export var SuccessImg = styled.div.withConfig({
  displayName: "style__SuccessImg",
  componentId: "sc-o3vsh2-26"
})(["min-width:128px;background:url('/static/directContentForm/tail.png') no-repeat;background-size:cover;@media only screen and ", "{width:140px;}"], responsiveQuery.desktop);
export var SuccessPointsStamp = styled.div.withConfig({
  displayName: "style__SuccessPointsStamp",
  componentId: "sc-o3vsh2-27"
})(["display:inline-block;width:46px;height:48px;position:absolute;top:30px;left:100px;background:url('/static/directContentForm/group-13-copy.svg') no-repeat;background-size:contain;@media only screen and ", "{width:54px;height:54px;left:95px;top:30px;}"], responsiveQuery.desktop);
export var SuccessTick = styled.div.withConfig({
  displayName: "style__SuccessTick",
  componentId: "sc-o3vsh2-28"
})(["width:34px;height:34px;position:absolute;top:-18px;right:-11px;background-size:contain;background-image:url('/static/directContentForm/group-2.svg');@media only screen and ", "{width:44px;height:44px;margin-left:367px;background-size:44px;right:-22px;}"], responsiveQuery.desktop);
export var SuccessPointsText = styled.div.withConfig({
  displayName: "style__SuccessPointsText",
  componentId: "sc-o3vsh2-29"
})(["display:inline-block;overflow:auto;font-style:normal;font-stretch:normal;letter-spacing:normal;color:", ";text-align:left;padding:0 15px 10px 26px;@media only screen and ", "{overflow:none;padding:0 30px 10px 30px;}"], colours.qantasCharcoal, responsiveQuery.desktop);
export var SuccessPointsTextHeader = styled.div.withConfig({
  displayName: "style__SuccessPointsTextHeader",
  componentId: "sc-o3vsh2-30"
})(["display:inline-block;width:100%;margin-top:16px;p{display:block;font-family:", ";font-size:0.9375rem;line-height:1.13;margin:0;text-transform:uppercase;@media only screen and ", "{overflow:none;font-size:", ";}}"], customFonts.ciutadellaMed, responsiveQuery.desktop, fontSizes.base);
export var SuccessPointsTextCopy = styled.div.withConfig({
  displayName: "style__SuccessPointsTextCopy",
  componentId: "sc-o3vsh2-31"
})(["display:inline-block;max-width:197px;margin-top:9px;p{display:block;font-size:", ";line-height:1.13;margin:0;}"], fontSizes.base);