import { layoutSizes, colours, customFonts, fontSizes } from "../../../constants/css";
import styled from 'styled-components';
import { Button } from "../../_global";
export var MerchantMore = styled.div.withConfig({
  displayName: "style__MerchantMore",
  componentId: "sc-1uqvt7i-0"
})(["background-color:", ";border:solid 1px #dbdbdb;padding:0 1rem 1rem 1rem;position:relative;margin-top:1rem;max-height:300px;@media only screen and (min-width:", "){margin-top:0;max-height:auto;}&:after,&:before{bottom:100%;left:10%;border:solid transparent;content:' ';height:0;width:0;position:absolute;pointer-events:none;}&:after{border-color:rgba(255,255,255,0);border-bottom-color:", ";border-width:15px;margin-left:-15px;}&:before{border-color:rgba(221,221,221,0);border-bottom-color:#ddd;border-width:16px;margin-left:-16px;}"], colours.qantasWhite, layoutSizes.desktopWidth, colours.qantasWhite);
export var LongDescButton = styled(Button).withConfig({
  displayName: "style__LongDescButton",
  componentId: "sc-1uqvt7i-1"
})(["position:absolute;right:-15px;text-align:center;padding:0.5rem;background-color:transparent;"]);
export var LongCopy = styled.div.withConfig({
  displayName: "style__LongCopy",
  componentId: "sc-1uqvt7i-2"
})(["padding-top:2.2rem;overflow-y:auto;max-height:250px;@media only screen and (min-width:", "){p:first-child{margin:0;}}"], layoutSizes.desktopWidth);
export var CouponExpiry = styled.p.withConfig({
  displayName: "style__CouponExpiry",
  componentId: "sc-1uqvt7i-3"
})(["font-size:", ";color:", ";font-family:", ";font-weight:normal;font-style:normal;font-stretch:normal;letter-spacing:normal;margin:0;width:30%;@media only screen and (min-width:", "){text-align:center;width:100%;}"], fontSizes.small, colours.qantasCharcoal, customFonts.ciutadellaMed, layoutSizes.desktopWidth);
export var DealCta = styled(Button).attrs({
  name: function name(props) {
    return props.hasName || null;
  }
}).withConfig({
  displayName: "style__DealCta",
  componentId: "sc-1uqvt7i-4"
})(["width:100%;margin:1rem auto;@media only screen and (min-width:", "){width:auto;margin:0.2rem 1rem 0;}"], layoutSizes.desktopWidth);
export var CardInfo = styled.div.withConfig({
  displayName: "style__CardInfo",
  componentId: "sc-1uqvt7i-5"
})(["display:none;", ""], function (props) {
  return props.isVisible && "\n    display: block;\n    margin-top: 1rem;\n    @media only screen and (min-width: ".concat(layoutSizes.desktopWidth, ") {\n      max-width: 60%;\n    }\n\n    p {\n      font-size: ").concat(fontSizes.small, ";\n    }\n\n    button {\n      border: none;\n      font-weight: 600;\n      cursor: pointer;\n      background-color: transparent;\n      margin-left: 90%;\n    }\n  ");
});
export var MerchantCoupon = styled.div.withConfig({
  displayName: "style__MerchantCoupon",
  componentId: "sc-1uqvt7i-6"
})(["min-height:5.6rem;background-color:", ";padding:1.25rem 2rem 1.25rem;position:relative;margin-bottom:1px;width:100%;box-shadow:0 2px 4px 0 #00000033,0 15px 15px 0 #0000000c;"], colours.qantasWhite);
export var FlexWrapper = styled.div.withConfig({
  displayName: "style__FlexWrapper",
  componentId: "sc-1uqvt7i-7"
})(["@media only screen and (min-width:", "){display:flex;}> svg{height:40px;margin-right:1rem;}"], layoutSizes.desktopWidth);
export var TitleWrapper = styled.div.withConfig({
  displayName: "style__TitleWrapper",
  componentId: "sc-1uqvt7i-8"
})(["text-align:center;@media only screen and (min-width:", "){min-width:40%;max-width:40%;text-align:left;}"], layoutSizes.desktopWidth);
export var CouponTitle = styled.h3.withConfig({
  displayName: "style__CouponTitle",
  componentId: "sc-1uqvt7i-9"
})(["font-family:", ";font-size:", ";color:", ";font-weight:normal;margin:0;"], customFonts.ciutadellaMed, fontSizes.large, colours.qantasCharcoal);
export var CouponWrapper = styled.div.withConfig({
  displayName: "style__CouponWrapper",
  componentId: "sc-1uqvt7i-10"
})(["position:relative;@media only screen and (min-width:", "){width:20%;display:flex;flex-direction:column;justify-content:center;}div:first-child{font-size:", ";display:none;position:absolute;left:40%;top:-10px;z-index:1;@media only screen and (min-width:", "){left:20%;}}"], layoutSizes.desktopWidth, fontSizes.small, layoutSizes.desktopWidth);
export var CouponCode = styled.div.withConfig({
  displayName: "style__CouponCode",
  componentId: "sc-1uqvt7i-11"
})(["", " color:", ";padding:0.3rem;height:33px;font-family:", ";font-size:", ";text-align:center;text-transform:uppercase;@media only screen and (min-width:", "){width:20%;}"], function (props) {
  return props.hasCode && "\n    background-color: ".concat(colours.qantasIceWhite, ";\n    color: ").concat(colours.qantasCharcoal, " !important;\n    padding: 0.5rem !important;\n    width: 100% !important;\n  ");
}, colours.qantasLightGrey, customFonts.ciutadellaMed, fontSizes.small, layoutSizes.desktopWidth);
export var Expander = styled.button.withConfig({
  displayName: "style__Expander",
  componentId: "sc-1uqvt7i-12"
})(["background:none;border:none;position:absolute;left:43%;@media only screen and (min-width:", "){position:relative;left:0;}"], layoutSizes.desktopWidth);
export var ExpanderPad = styled.div.withConfig({
  displayName: "style__ExpanderPad",
  componentId: "sc-1uqvt7i-13"
})(["min-width:2.6rem;"]);
export var DesktopExpander = styled.span.withConfig({
  displayName: "style__DesktopExpander",
  componentId: "sc-1uqvt7i-14"
})(["display:none;@media only screen and (min-width:", "){display:flex;flex-direction:column;justify-content:center;}"], layoutSizes.desktopWidth);
export var ImportantButton = styled(Button).withConfig({
  displayName: "style__ImportantButton",
  componentId: "sc-1uqvt7i-15"
})(["text-transform:none;text-decoration:underline;padding:5px 1rem;letter-spacing:normal;font-size:0.8rem;color:#666666;padding:0;text-align:right;width:36%;@media only screen and (min-width:", "){text-align:center;width:100%;}"], layoutSizes.desktopWidth);
export var InfoWrapper = styled.div.withConfig({
  displayName: "style__InfoWrapper",
  componentId: "sc-1uqvt7i-16"
})(["@media only screen and (min-width:", "){border-left:3px solid ", ";padding-left:1.6rem;margin-left:1rem;}"], layoutSizes.desktopWidth, colours.qantasCharcoal);
export var CouponBody = styled.p.withConfig({
  displayName: "style__CouponBody",
  componentId: "sc-1uqvt7i-17"
})(["margin:0 0 1rem;color:#666666;text-align:center;@media only screen and (min-width:", "){text-overflow:ellipsis;white-space:nowrap;overflow:hidden;text-align:left;margin:0;}"], layoutSizes.desktopWidth);
export var DateWrapper = styled.div.withConfig({
  displayName: "style__DateWrapper",
  componentId: "sc-1uqvt7i-18"
})(["display:flex;justify-content:space-between;align-items:center;@media only screen and (min-width:", "){width:20%;flex-direction:column;justify-content:center;}"], layoutSizes.desktopWidth);
export var BoxContainer = styled.div.withConfig({
  displayName: "style__BoxContainer",
  componentId: "sc-1uqvt7i-19"
})(["background-color:", ";border-radius:4px;padding:1.1rem;width:263px;min-height:290px;text-align:left;font-size:", ";color:#f47b17;h3{margin:0;font-family:", ";font-size:", ";font-weight:normal;display:inline-block;}svg{vertical-align:middle;margin-right:6px;display:inline-block;}"], colours.qantasWhite, fontSizes.small, customFonts.ciutadellaMed, fontSizes.small);
export var ExclusionContainer = styled.div.withConfig({
  displayName: "style__ExclusionContainer",
  componentId: "sc-1uqvt7i-20"
})(["position:relative;"]);
export var ExclusionTitle = styled.div.withConfig({
  displayName: "style__ExclusionTitle",
  componentId: "sc-1uqvt7i-21"
})(["display:inline-flex;align-items:center;cursor:pointer;> span{font-size:", ";color:#f4750c;border-bottom:1px dashed;}> svg{margin-right:6px;}"], fontSizes.small);
export var ExclusionContent = styled.div.withConfig({
  displayName: "style__ExclusionContent",
  componentId: "sc-1uqvt7i-22"
})(["position:absolute;top:2.5rem;z-index:9999;border-radius:4px;border:1px solid #f4750c;left:-120px;&:after,&:before{bottom:100%;right:10%;border:solid transparent;content:' ';height:0;width:0;position:absolute;pointer-events:none;}&:after{border-bottom-color:", ";border-width:10px;margin-left:-10px;width:3px;}&:before{border-bottom-color:#f4750c;border-width:12px;margin-right:-1px;width:1px;}", "{padding:24px 15px;width:199px;min-height:238px;}"], colours.qantasWhite, BoxContainer);