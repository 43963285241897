var __jsx = React.createElement;
import React from 'react';
import { H2Styled } from "./style";
var PanelHeading = function PanelHeading(_ref) {
  var content = _ref.content;
  return __jsx(H2Styled, null, content && content.map(function (item, index) {
    return index === 1 ? __jsx("span", {
      key: "".concat(index, "-").concat(content)
    }, item, "\xA0") : __jsx(React.Fragment, {
      key: "".concat(index, "-").concat(content)
    }, item, "\xA0");
  }));
};
export default PanelHeading;