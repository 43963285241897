import styled from 'styled-components';
var pebbleBackground = "/static/offers/pebbles-background.png";
import Button from "../../_global/Button";
import { layoutSizes, colours, fontSizes, customFonts } from "../../../constants/css";
import { SuccessIcon, SuccessHeading, SuccessCopy } from "../RewardsLanding/HowToLink/style";
export var SuccessTick = styled.div.withConfig({
  displayName: "style__SuccessTick",
  componentId: "sc-6wc9jv-0"
})(["width:34px;height:34px;background-size:contain;background-image:url('/static/directContentForm/group-2.svg');margin-top:1em;@media only screen and (min-width:", "){width:44px;height:44px;margin-top:2em;background-size:44px;}"], layoutSizes.desktopWidth);
export var MerchantPanelContainerWithHeader = styled.div.withConfig({
  displayName: "style__MerchantPanelContainerWithHeader",
  componentId: "sc-6wc9jv-1"
})(["width:303px;@media only screen and (min-width:", "){width:auto;}"], layoutSizes.desktopWidth);
export var MerchantPanelContainer = styled.div.withConfig({
  displayName: "style__MerchantPanelContainer",
  componentId: "sc-6wc9jv-2"
})(["display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%;border-radius:4px;box-shadow:0 2px 4px 0 rgba(0,0,0,0.2),0 15px 15px 0 rgba(0,0,0,0.05),0 1px 1px 0 rgba(0,0,0,0.1);background-color:", ";max-width:303px;margin-bottom:32px;margin-top:10px;@media only screen and (min-width:", "){flex-direction:row;justify-content:space-evenly;max-width:802px;padding:0;margin-top:16px;}"], colours.qantasWhite, layoutSizes.desktopWidth);
export var LeftContainer = styled.div.withConfig({
  displayName: "style__LeftContainer",
  componentId: "sc-6wc9jv-3"
})(["background:", ";width:100%;display:flex;flex-direction:column;justify-content:flex-start;padding:40px 18px 15px 18px;@media only screen and (min-width:", "){background:url(", ") no-repeat;background-color:", ";background-size:80%;align-items:flex-start;background-position:left bottom;width:398px;padding:37px 0 17px 40px;height:100%;min-height:295px;margin:0;}"], colours.qantasWhite, layoutSizes.desktopWidth, pebbleBackground, colours.qantasWhite);
export var RightContainer = styled.div.withConfig({
  displayName: "style__RightContainer",
  componentId: "sc-6wc9jv-4"
})(["display:flex;flex-direction:column;justify-content:flex-end;align-items:center;width:100%;padding:0 18px;background:url(", ") no-repeat;background-size:80%;background-position:left bottom;background-color:#f9f9f9;@media only screen and (min-width:", "){background-image:none;justify-content:center;width:398px;min-height:275px;margin-right:8px;padding:0;}", "{width:32px;height:32px;margin-top:20px;}", "{font-size:1.375rem;margin:10px;}", "{margin-top:3px;max-width:312px;}"], pebbleBackground, layoutSizes.desktopWidth, SuccessIcon, SuccessHeading, SuccessCopy);
export var PStyled = styled.p.withConfig({
  displayName: "style__PStyled",
  componentId: "sc-6wc9jv-5"
})(["text-align:center;font-size:", ";@media only screen and (min-width:", "){margin-top:32px;font-size:", ";width:320px;}"], fontSizes.base, layoutSizes.desktopWidth, fontSizes.large);
export var LinkStyled = styled(Button).withConfig({
  displayName: "style__LinkStyled",
  componentId: "sc-6wc9jv-6"
})(["color:", ";text-transform:none;font-family:", ";font-size:", ";font-weight:normal;font-style:normal;font-stretch:normal;line-height:1.75;letter-spacing:normal;text-align:center;outline:none;text-decoration:underline;"], colours.qantasCharcoal, customFonts.ciutadellaReg, fontSizes.base);
export var MerchantLogos = styled.img.withConfig({
  displayName: "style__MerchantLogos",
  componentId: "sc-6wc9jv-7"
})(["width:267px;@media only screen and (min-width:", "){width:294px;}"], layoutSizes.desktopWidth);
export var ButtonStyled = styled(Button).withConfig({
  displayName: "style__ButtonStyled",
  componentId: "sc-6wc9jv-8"
})(["width:100%;margin-top:20px;@media only screen and (min-width:", "){width:270px;}margin-bottom:1em;"], layoutSizes.desktopWidth);
export var MerchantLogosSuccess = styled(MerchantLogos).withConfig({
  displayName: "style__MerchantLogosSuccess",
  componentId: "sc-6wc9jv-9"
})(["margin-bottom:10px;@media only screen and (min-width:", "){width:276px;height:42px;}"], layoutSizes.desktopWidth);
export var LoadingContainer = styled.div.withConfig({
  displayName: "style__LoadingContainer",
  componentId: "sc-6wc9jv-10"
})(["padding:40px 0;@media only screen and (min-width:", "){padding:0;}"], layoutSizes.desktopWidth);