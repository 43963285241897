import styled from 'styled-components';
export var CardIconsContainer = styled.div.withConfig({
  displayName: "style__CardIconsContainer",
  componentId: "sc-iupsgg-0"
})(["display:flex;justify-content:left;align-items:center;"]);
export var CardIconLabel = styled.p.withConfig({
  displayName: "style__CardIconLabel",
  componentId: "sc-iupsgg-1"
})(["font-size:", ";line-height:1;text-transform:uppercase;color:", ";margin:0 0 6px 0;@media ", "{font-size:", ";line-height:0.81;margin:0 0 14px 0;}"], function (_ref) {
  var theme = _ref.theme;
  return theme.fontSizes.small;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colours.qantasCharcoal;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.responsiveQuery.desktop;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.fontSizes.base;
});
export var CardIconImg = styled.img.withConfig({
  displayName: "style__CardIconImg",
  componentId: "sc-iupsgg-2"
})(["display:block;width:46px;height:46px;@media ", "{width:58px;height:58px;}& + img{margin-left:5px;}"], function (_ref5) {
  var theme = _ref5.theme;
  return theme.responsiveQuery.desktop;
});