var __jsx = React.createElement;
import React from 'react';
import { OfferPointsDescription } from "./style";
import { PStyled } from "../style";
var EarnPoints = function EarnPoints() {
  return __jsx(OfferPointsDescription, null, __jsx(PStyled, {
    align: "center",
    mobileAlign: "center"
  }, "Shop your way to a holiday with Everyday Rewards. It's one of the easiest ways to earn Qantas Points on the ground. Whether it's your weekly groceries from Woolworths, everyday essentials from BIG W or drinks from BWS, simply shop and scan and every shop is a step closer to your next holiday."));
};
export default EarnPoints;